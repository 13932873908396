var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-5 text-center justify-center"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({attrs:{"link":"","outlined":""}},'v-card',attrs,false),on),[_c('v-card-title',[_vm._v("Partenaires acceptés")]),_c('v-card-text',[_c('v-icon',{attrs:{"size":"48","color":"EoleGreen"}},[_vm._v("mdi-check-decagram")]),_c('h2',[_vm._v("0")])],1)],1)]}}])},[_c('span',[_vm._v("Les partenaires acceptés sont ceux qui apparaîtrons dans la liste générale des partenaires possible à ajouter au configurateur des cabinets. ")])])],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({attrs:{"link":"","outlined":""}},'v-card',attrs,false),on),[_c('v-card-title',[_vm._v("Partenaires en attente")]),_c('v-card-text',[_c('v-icon',{attrs:{"size":"48","color":"EoleYellow"}},[_vm._v("mdi-minus-thick")]),_c('h2',[_vm._v(_vm._s(_vm.itemsPartnersApprovedDisapproved.length))])],1)],1)]}}])},[_c('span',[_vm._v("Les partenaires en attente sont ceux pour qui les cabinets ont effectués une demande d'approbation et qui sont en attente de décision finale. "),_c('br'),_vm._v(" Cependant il reste disponible pour le cabinet ayant effectué la demande d'approbation. ")])])],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({attrs:{"link":"","outlined":""}},'v-card',attrs,false),on),[_c('v-card-title',[_vm._v("Partenaires refusés")]),_c('v-card-text',[_c('v-icon',{attrs:{"size":"48","color":"EoleError"}},[_vm._v("mdi-close-circle")]),_c('h2',[_vm._v("0")])],1)],1)]}}])},[_c('span',[_vm._v("Les partenaires refusés n'apparaîtrons pas dans la liste générale des partenaires possible à ajouter au configurateur des cabinets. "),_c('br'),_vm._v(" Cependant il reste disponible pour le cabinet ayant effectué la demande d'approbation. ")])])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","loading":_vm.loading,"loading-text":"Chargement des produits en cours... Merci de patienter","page":_vm.page,"items-per-page":10,"headers":_vm.headers,"items":_vm.itemsPartnersApprovedDisapproved,"hide-default-footer":"","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":"","no-results-text":("Aucune donnée trouvée ayant le mot clé " + (this.search)),"sort-by":"calories","footer-props":{
      'items-per-page-text': 'produits par page(s)',
    },"search":_vm.search,"custom-filter":_vm.filterOnlyCapsText},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialogAddCompanyToProduct),callback:function ($$v) {_vm.dialogAddCompanyToProduct=$$v},expression:"dialogAddCompanyToProduct"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v(" Modification du produit")],1)]),_c('v-card-text',[_c('v-container',[_c('v-divider',{staticClass:"mb-5"}),_c('v-alert',{attrs:{"type":"info","dense":"","color":"EoleBlue","prominent":"","text":""}},[_vm._v("En selectionnant une compagnie ci-dessous vous pouvez l'ajouter à la liste des compagnies proposant le produit "+_vm._s(_vm.editedItem.name))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"label":("Compagnie à ajouter à la liste du produit " + (_vm.editedItem.name)),"outlined":"","items":_vm.companyAvailable,"item-text":"name","item-value":"id"},model:{value:(_vm.companyToAdd),callback:function ($$v) {_vm.companyToAdd=$$v},expression:"companyToAdd"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"pa-5",attrs:{"color":"warning","text":""},on:{"click":_vm.close}},[_vm._v("Annuler")]),_c('v-btn',{staticClass:"white--text pa-5",attrs:{"color":"EoleBlue"},on:{"click":function($event){return _vm.postProductLinked({
                  productName: _vm.editedItem.name,
                  idProduct: _vm.editedItem.id,
                  idInsurer: _vm.companyToAdd,
                })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil ")]),_vm._v("Ajouter la compagnie au produit "+_vm._s(_vm.editedItem.name))],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" Supression du produit")],1)]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('h3',[_vm._v("Êtes-vous sûr de vouloir supprimer le produit : "+_vm._s(_vm.editedItem.name)+" ?")]),_c('p',[_vm._v(" En supprimant ce produit, il ne sera plus proposé lors de la conception de la Lettre de Devoir de Conseil (LDDC) ")])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"EoleYellow","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Annuler")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"EoleError"},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Confirmer la suppression")])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","text":"","icon":"","color":"EoleGreen"},on:{"click":function($event){return _vm.approveInsurer(item.idInsurer, 1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-check-decagram ")])],1)]}}],null,true)},[_c('span',[_vm._v("Approuver la demande pour "+_vm._s(item.name))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","text":"","icon":"","color":"EoleError"},on:{"click":function($event){return _vm.approveInsurer(item.idInsurer, 2)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Refuser la demande pour "+_vm._s(item.name))])])]}},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"ma-2",attrs:{"color":"error","text":""}},[_c('div',{staticClass:"d-flex align-start"},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert-circle-outline ")]),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-base font-weight-medium mb-1"},[_vm._v(" Impossible d'accéder aux données. Merci de réessayer ultérieurement. ")])])],1)]),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.fetchInsurersByProduct()}}},[_vm._v("Réessayer")])]},proxy:true},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{staticClass:"pa-5",attrs:{"colspan":headers.length}},[_c('h2',[_vm._v("Informations à propos de "+_vm._s(item.name))]),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"id":"identity","outlined":"","label":"Identité de la société","readonly":"","prepend-inner-icon":"mdi-account","value":item.name}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"id":"identity","outlined":"","label":"Code postale du siège","readonly":"","prepend-inner-icon":"mdi-city","value":item.zip}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"id":"identity","outlined":"","label":"Adresse du siège","readonly":"","prepend-inner-icon":"mdi-city","value":item.street}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"id":"identity","outlined":"","label":"Ville du siège","readonly":"","prepend-inner-icon":"mdi-city","value":item.city}})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',{staticClass:"mb-2",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"white--text ma-2",attrs:{"href":item.url,"target":"_blank","color":"EoleBlue"}},[_vm._v("Consulter le site internet "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-search-web")])],1),_c('v-btn',{staticClass:"white--text ma-2",attrs:{"href":("https://www.pappers.fr/recherche?q=" + (item.siret)),"target":"_blank","color":"EoleYellow"}},[_vm._v("Consulter les données pappers "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-open-in-new")])],1)],1)],1)]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }